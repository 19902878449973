import axios from 'axios';
import React, { Dispatch, SyntheticEvent, useEffect, useState } from 'react';
import Wrapper from "../components/Wrapper";
import { connect } from "react-redux";
import { User } from "../models/user";
import { setUser } from "../redux/actions/setUserAction";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')

const {REACT_APP_API_URL} = process.env
const Profile = (props: { user: User, setUser: (user: User) => void }) => {

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');



    useEffect(() => {
        setFirstName(props.user.first_name);
        setLastName(props.user.last_name);
        setEmail(props.user.email);

    }, [props.user]);

    const infoSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        // const {data} = await axios.put('users/info', {
        //     first_name,
        //     last_name,
        //     email
        // });

  
        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
        const response = await fetch(`${REACT_APP_API_URL}/users/info`, {
            method: "PUT",
           // headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            mode: "no-cors",
            body: JSON.stringify({
                first_name,
                last_name,
                email
            })
        })

        const data = await response.json();



        props.setUser(new User(
            data.id,
            data.first_name,
            data.last_name,
            data.email,
            data.role
        ));
    }

    const passwordSubmit = async (e: SyntheticEvent) => {

        e.preventDefault();

      

        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
         await fetch(`${REACT_APP_API_URL}/users/password`, {
            method: "PUT",
            //headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            mode: "no-cors",
            body: JSON.stringify({
                password,
                password_confirm
            })
        })

        //const data = await response.json();

        




    }
    return (
        <Wrapper>

            <h3>Account Information</h3>
            <form onSubmit={infoSubmit}>
                <div className="mb-3">
                    <label htmlFor="">First Name</label>
                    <input type="text" className="form-control"
                        defaultValue={first_name}
                        onChange={e => setFirstName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="">Last Name</label>
                    <input type="text" className="form-control"
                        defaultValue={last_name}
                        onChange={e => setLastName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="">Email</label>
                    <input type="text" className="form-control"
                        defaultValue={email}
                        onChange={e => setEmail(e.target.value)} />

                </div>

                <button className="btn btn-outline-secondary">Save</button>
            </form>

            <h3 className="mt-4">Change Password</h3>
            <form onSubmit={passwordSubmit}>
                <div className="mb-3">
                    <label htmlFor="">Password</label>
                    <input type="password" className="form-control"
                        defaultValue={password}
                        onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="">Password Confirm</label>
                    <input type="password_confirm" className="form-control"
                        defaultValue={password_confirm}
                        onChange={e => setPasswordConfirm(e.target.value)} />
                </div>

                <button className="btn btn-outline-secondary">Save</button>

            </form>

        </Wrapper>
    );
};

export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    },
    (dispatch: Dispatch<any>) => {
        return {
            setUser: (user: User) => dispatch(setUser(user))
        }
    }
)(Profile);