import React, { useEffect } from 'react';

import * as c3 from 'c3';
import axios from 'axios';
import AdminWrapper from '../../components/AdminWrapper';
import { useState } from 'react';


const { REACT_APP_API_URL } = process.env


const Admin = () => {

    
    


    return (
        <AdminWrapper>
            <h2>Administrator Page</h2>
            


        </AdminWrapper>
    )

}
export default Admin
