import axios from 'axios';
import React, { SyntheticEvent,Component, Dispatch, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import Wrapper from '../components/Wrapper';
import Cookies from 'universal-cookie';
import { User } from '../models/user';
import {connect} from "react-redux";
import { setUser } from '../redux/actions/setUserAction';
import { setDirectoryName } from '../redux/actions/documentAction';

const cookies = new Cookies();


const { REACT_APP_API_URL } = process.env

const SSO = (props: any) => {

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  
  console.log(cookies.get('securego'))

  useEffect(() => {
    (
        async () => {
            try {
                
              var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
                
                //const { data } = await axios.get("user");
                const response = await fetch(`${REACT_APP_API_URL}/user`,{
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }
                   
                    //credentials: 'include',
                })

               const data = await response.json();

               var user = new User()
               user.id = data.id
               user.first_name = data.first_name
               user.last_name = data.last_name
               user.email = data.email
               user.role = data.role


              props.setUser(user);
                
               
            } catch (e) {
                setRedirect(true);
            }


        }
    )();
}, []);



  if (redirect) {
    return <Redirect to={'/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO'} />;
  }
  if(cookies.get('securego')){
    return <Redirect to={'/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO'} />;
  }

  return (

    <main className="form-signin">
       <h1>Please sigin in</h1>
    </main>

  )
}

const mapStateToProps = (state: any) => {
  return {
      user: state.users.user,
      directoryname: state.directorys.directoryname
  };
}


const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
      setUser: (user: User) => dispatch(setUser(user)),
      setDirectoryName: (directoryname: string) => dispatch(setDirectoryName(directoryname)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SSO);