import React, { SyntheticEvent, useEffect, useState } from 'react';

import * as c3 from 'c3';
import axios from 'axios';
import AdminWrapper from '../../../components/AdminWrapper';
import { Link } from 'react-router-dom';
import { Folder } from '../../../models/folder';
import { FoldersRole } from '../../../models/foldersrole';
import Modal from 'react-modal';
import { User } from '../../../models/user';
import { setUser } from '../../../redux/actions/setUserAction';
import { FoldersUser } from '../../../models/foldersuser';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')


const { REACT_APP_API_URL,REACT_APP_LOCAL_URL } = process.env


const AdminDocuments = () => {
    const [folders, setFolders] = useState([]);
    // const [addFolder, setAddFolder] = useState(false);
    const [addSuperUsermodalIsOpen, setAddSuperUserIsOpen] = useState(false);
    const [viewSuperUsermodalIsOpen, setViewSuperUserIsOpen] = useState(false);
    const [folderID, setFolderID] = useState("")
    const [folderName, setFolderName] = useState("")
    const [userID, setUserID] = useState("")
    const [folderRoleID, setFolderRoleID] = useState("")
    const [users, setUsers] = useState([]);
    const [foldersRoles, setFoldersRoles] = useState([]);
    const [hasError, setErrors] = useState(false)
    const [foldersUser, setFoldersUser] = useState([]);


    const addSuperUseropenModal = () => {

        setAddSuperUserIsOpen(true);
    }
    const addSuperUsercloseModal = () => {

        setAddSuperUserIsOpen(false);
    }
    const viewSuperUseropenModal = () => {

        setViewSuperUserIsOpen(true);
    }
    const viewSuperUsercloseModal = () => {

        setViewSuperUserIsOpen(false);
    }

    useEffect(() => {
        (
            async () => {
                //const { data } = await axios.get('users?page=${page}');

                const response = await fetch(`${REACT_APP_API_URL}/directories`, {
                    method:"GET",
                    //headers: { 'Content-Type': 'application/json' },
                    //credentials: 'include',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }
                })

                const data = await response.json();

                setFolders(data);

            }
        )()
    }, []);

    const handleSubmitAddSuperUser = async (e: SyntheticEvent) => {
        e.preventDefault();

        const response = await fetch(`${REACT_APP_API_URL}/directories/createfoldersuser`, {
            method: 'POST',
            //headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            body: JSON.stringify({
                user_id: userID,
                folder_id: folderID,
                folder_role_id: folderRoleID,
            })
        })
        response
            .json()
            .then(res => {

                if (res.id) {
                    alert("Added success!")
                    addSuperUsercloseModal();
                } else {
                    alert(res.message)
                }




            })
            .catch(err => setErrors(err));


    }

  
    async function GetUsers() {
        const response = await fetch(`${REACT_APP_API_URL}/directories/users`, {
            //headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            }
        })

        const data = await response.json();

        setUsers(data);

    }
    async function GetFoldersUser(_folderID: string) {
        const response = await fetch(`${REACT_APP_API_URL}/directories/foldersuser/${_folderID}`, {
            method: "GET",
            //headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            }
        })

        const data = await response.json();

        setFoldersUser(data);

    }
    async function DeleteFoldersUser(_id: string) {
        await fetch(`${REACT_APP_API_URL}/directories/foldersuser/${_id}`, {
            method: "DELETE",
           // headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            }
        })



    }
    const addSuperUser = async (_id: string, _name: string) => {
        setFolderID(_id)
        setFolderName(_name)
        
        await GetUsers();
        addSuperUseropenModal();

    }
    const viewSuperUser = async (_id: string) => {

        await GetFoldersUser(_id);
        viewSuperUseropenModal();

    }
    const deleteSuperUser = async (_folder_id: string, _id: string, _name: string) => {


        await DeleteFoldersUser(_id);
        await GetFoldersUser(_folder_id);
        viewSuperUseropenModal();

    }

    const goToPage = async ( _id: string, _name: string) => {

        const win: any = window.open(`${REACT_APP_LOCAL_URL}/directories/${_id}/${_name}`, "_blank");
        win.focus();
       

    }



    return (
        <AdminWrapper>
            <div className="pt-3 pb-2 mb-3 border-bottom">
                <Link to="/admin/documents/create" className="btn btn-sm btn-outline-secondary">+ New</Link>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>

                            <th>Directory Name</th>

                            <th>Super User</th>
                        </tr>
                    </thead>
                    <tbody>
                        {folders.map((folder: Folder) => {
                            return (
                                <tr key={folder.id}>

                                    <td>{folder.name}</td>

                                    <td>
                                        <div className="btn-group mr-2">
                                            {/* <Link to={`/users/${user.id}/edit`} className="btn btn-sm btn-outline-secondary">Super User</Link> */}
                                            <a href="#" className="btn btn-sm btn-outline-secondary" onClick={() => viewSuperUser(folder.id)}>View</a> &nbsp;
                                            <a href="#" className="btn btn-sm btn-outline-secondary" onClick={() => addSuperUser(folder.id, folder.name)}>Add</a>&nbsp;
                                            <a href="#" className="btn btn-sm bg-primary text-light" onClick={() => goToPage(folder.id, folder.name)}>Go to Page</a>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>

            {/* <Paginator page={page} lastPage={lastPage} pageChanged={setPage}/> */}

            <Modal
                isOpen={addSuperUsermodalIsOpen}
                onRequestClose={addSuperUsercloseModal}

                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)'
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px',
                        background: 'white',
                        border: '0px solid black'
                    },
                }}
                contentLabel="Example Modal"

            >
                <form onSubmit={handleSubmitAddSuperUser}>
                    <div className="card mb-3 border-0" >
                        <div className="card-header bg-transparent border-0">
                            <div className="container">

                                <div className="row">
                                    <div className="col-11"><b>Add Super User to Folder</b></div>
                                    <div className="col-1">
                                        <button className="btn" onClick={addSuperUsercloseModal}>X</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body text-success border-0">

                            <div className="mb-3">
                                <label>Folder ID</label>
                                <input className="form-control" disabled value={folderID} onChange={e => setFolderID(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Folder Name</label>
                                <input className="form-control" disabled value={folderName} onChange={e => setFolderName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>User</label>
                                <select className="form-control" onChange={e => setUserID(e.target.value)}>
                                    <option value=""></option>
                                    {users.map((r: User) => {
                                        return (
                                            <option key={r.id} value={r.id}>{r.email}</option>
                                        )
                                    })}
                                </select>
                            </div>

                           




                            {/* <input type="text" onChange={(e) => setFolderID(e.target.value)} autoFocus className="form-control" style={{ background: '#eee' }} />
                        <input type="text" onChange={(e) => setUserID(e.target.value)} autoFocus className="form-control" style={{ background: '#eee' }} />
                        <input type="text" onChange={(e) => setFolderRoleID(e.target.value)} className="form-control" style={{ background: '#eee' }} /> */}
                        </div>
                        <div className="card-footer bg-transparent border-0">

                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" onClick={(e) => handleSubmitAddSuperUser(e)}>Add</button>

                            </div>
                        </div>
                    </div>
                </form>

            </Modal>

            <Modal
                isOpen={viewSuperUsermodalIsOpen}
                onRequestClose={viewSuperUsercloseModal}

                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)'
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '500px',
                        background: 'white',
                        border: '0px solid black'
                    },
                }}
                contentLabel="Example Modal"

            >

                <div className="card mb-3 border-0" >
                    <div className="card-header bg-transparent border-0">
                        <div className="container">

                            <div className="row">
                                <div className="col-11"><b>View Super User</b></div>
                                <div className="col-1">
                                    <button className="btn" onClick={viewSuperUsercloseModal}>X</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body text-success border-0">

                        <div className="table-responsive">
                            <table className="table table-striped table-sm">
                                <thead>
                                    <tr>

                                        <th>Folder&nbsp;Name</th>

                                        <th>User Name</th>

                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {foldersUser.map((foldersUser: FoldersUser) => {
                                        return (
                                            <tr key={foldersUser.id}>

                                                <td>{foldersUser.folder.name}</td>
                                                <td>{foldersUser.user.email}</td>

                                                <td>
                                                    <div className="btn-group mr-2">

                                                        <a href="#" className="btn btn-sm btn-outline-secondary" onClick={() => deleteSuperUser(foldersUser.folder_id, foldersUser.id, foldersUser.folder.name)}>Delete</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>


                    </div>
                    <div className="card-footer bg-transparent border-0">

                        <div className="d-flex justify-content-end">
                            {/* <button className="btn btn-primary" onClick={() => viewSuperUsercloseModal()}>Close</button> */}

                        </div>
                    </div>
                </div>


            </Modal>


        </AdminWrapper>
    )

}
export default AdminDocuments
