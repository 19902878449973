import React, { SyntheticEvent } from 'react';



const Paginator = (props:{
    page:number,lastPage:number,pageChanged: (page: number) => void
}) => {


   

    const next = (e: SyntheticEvent) => {
        e.preventDefault()
        if(props.page + 1  > props.lastPage ){

        }else{
            if(props.page <= props.lastPage){
                props.pageChanged(props.page + 1);
            }
        }
       
        //alert(props.page + 1)
    }

    const prev = (e: SyntheticEvent) => {
        e.preventDefault()
        if(props.page - 1 == 0){

        }else{
            if(props.page >= 1){
                props.pageChanged(props.page - 1);
            }
        }
        
        //  alert(props.page - 1)
    }
    return (
       <>
         <nav>
             {props.lastPage > 0 ? (
                 <ul className="pagination">
                 <li className="page-item">
                     <a href="#" className="page-link" onClick={prev}>Previous</a>
                 </li>
                 <li className="page-item">
                     <div className="container">{props.page}  / {props.lastPage}</div>
                 </li>

                 <li className="page-item">
                     <a href="#" className="page-link" onClick={next}>Next</a>
                 </li>
             </ul>
             ): (
                <div></div>
             )}
              
        </nav>
       
       </>
       
    )
}

export default Paginator;