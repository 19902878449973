import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { User } from "../models/user";
import { connect } from "react-redux";
import logo from "../logo.png"
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const { REACT_APP_API_URL,REACT_APP_ENV,REACT_APP_DOMAIN_NAME } = process.env


const AdminNav = (props: { user: User }) => {


  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);


  const [click, setClick] = useState(false)
  const handleClick = () => {

    setClick(!click)
    setClickInspire(false)
    setClickInformation(false)
    setClickIdea(false)
  };

  const [clickInspire, setClickInspire] = useState(false)
  const handleClickInspire = () => {

    setClick(false)
    setClickInspire(!clickInspire)
    setClickInformation(false)
    setClickIdea(false)
  };

  const [clickInformation, setClickInformation] = useState(false)
  const handleClickInformation = () => {

    setClick(false)
    setClickInspire(false)
    setClickInformation(!clickInformation)
    setClickIdea(false)
  };

  const [clickIdea, setClickIdea] = useState(false)
  const handleClickIdea = () => {

    setClick(false)
    setClickInspire(false)
    setClickInformation(false)
    setClickIdea(!clickIdea)
  };

  const logout = async () => {
    // await axios.post("logout", {

    // })
    var bearer = 'Bearer ' + cookies.get('securego')
    await fetch(`${REACT_APP_API_URL}/logout`, {
      method: 'POST',
      //headers: { 'Content-Type': 'application/json' },
      //credentials: 'include',
      headers:{
        'Content-Type':'application/json',
        'Authorization': bearer
    },
   

    })
    // cookies.remove("user");
    // localStorage.removeItem('ispacezone-jwt')
    // localStorage.removeItem('groupname-jwt')
    // localStorage.removeItem('accessToken')
    // localStorage.removeItem('ispacezone-user')
    // localStorage.removeItem('ispacezone-jwt')
    // localStorage.removeItem('accessToken')


    

  //   if(REACT_APP_ENV === 'dev'){
  //     return <Redirect to="/adminlogin" />
  // }else{
  //     window.open(REACT_APP_DOMAIN_NAME, "_self");
  // }
  return <Redirect to="/admin/login" />


  }


  // try {
  //   if(props.user){
  //     if(props.user.role.name != "admin"){
  //      return <Redirect to={'/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO'} />;
  //     }
  //  }
  // } catch (error) {
    
  // }

  return (
    <>
      <nav className="navbar sticky-top bg-white flex-md-nowrap p-0">
      <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">
      <img src={logo} alt="Logo" />
      </a>
    {props.user ? (
       <ul className="my-2 my-md-0 mr-md-3">
        
      
       {props.user.first_name} [{props.user.role.name}]
     <Link to="/admin/login" className="p-2 text-decoration-none"
       onClick={logout}
     >Sign out</Link>
   </ul>
  
    ):
    (
      <span></span>
    )}
     
    </nav>



      {/* <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">
            <img src={logo} alt="Logo" />
          </a>
          <button className="navbar-toggler" aria-expanded={!isNavCollapsed ? true : false} type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-label="Toggle navigation"
            onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon" />
          </button>

          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item" style={{"fontWeight":"bold","color":"black"}}>
                <a className="nav-link active" aria-current="page" href="https://ispacezone.com/landingpage">Landing Page</a>
              </li>
              <li className="nav-item" style={{"fontWeight":"bold","color":"black"}}>
                <a className="nav-link" href="https://ispacezone.com/incubationlandingpage">Incubation</a>
              </li>
              <li className="nav-item dropdown" style={{"fontWeight":"bold","color":"black"}}>
                <a onClick={handleClickInspire}  className={`nav-link dropdown-toggle ${clickInspire ? 'show' : ''}`} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
                aria-expanded={!clickInspire ? true : false}>
                  Inspire
              </a>
                <ul style={{'background':'#FAC51E'}} className={`dropdown-menu ${clickInspire ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://ispacezone.com/inspire">New Issues</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/we-transform">WeTransform</a></li>
                 
                </ul>
              </li>
              <li className="nav-item dropdown" style={{"fontWeight":"bold","color":"black"}}>
                <a onClick={handleClickInformation}  className={`nav-link dropdown-toggle ${clickInformation ? 'show' : ''}`} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
                aria-expanded={!clickInformation ? true : false}>
                  Information
              </a>
                <ul style={{'background':'#FAC51E'}} className={`dropdown-menu ${clickInformation ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://ispacezone.com/TrainingMaterials">Innovation Materials</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/internal-new">Internal News</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/BooksSuggestion">Book Suggestion</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown" style={{"fontWeight":"bold","color":"black"}}>
                <a onClick={handleClickIdea}  className={`nav-link dropdown-toggle ${clickIdea ? 'show' : ''}`} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
                aria-expanded={!clickIdea ? true : false}>
                  Idea
              </a>
                <ul style={{'background':'#FAC51E'}} className={`dropdown-menu ${clickIdea ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://ispacezone.com/PageIdea">Idea</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/idealanding">Idea Tank</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/addnewidea">Add Idea Tank</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown" style={{"fontWeight":"bold","color":"black"}}>
                <a onClick={handleClick}  className={`nav-link dropdown-toggle ${click ? 'show' : ''}`} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
                aria-expanded={!click ? true : false}>
                  Compliance
              </a>
                <ul style={{'background':'#FAC51E'}} className={`dropdown-menu ${click ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://ispacezone.com/law-landing">Law & Regulation</a></li>
                  <li><a className="dropdown-item" href="https://app.ispacezone.com/docs/ff6df5d8-1d1c-4710-bf14-bbc2a27a54be/ISO">ISO Quality Control</a></li>
                  <li><a className="dropdown-item" href="#">PDPA Database</a></li>
                </ul>
              </li>

            </ul>
            <span className="navbar-text">
              {props.user.name}
              <Link to="/adminlogin" className="p-2 text-decoration-none"
                onClick={logout}
              >Sign out</Link>
            </span>
          </div>
        </div>
      </nav> */}





    </>
  )
}



export default connect(
  (state: any) => {
    return {
      user: state.users.user
    };
  }
)(AdminNav);