
import { useEffect, useState } from 'react';
import {NavLink } from 'react-router-dom';
import { Folder } from '../models/folder';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const { REACT_APP_API_URL, REACT_APP_LOCAL_URL } = process.env

const AdminMenu = () => {

  const [directories, SetDirectories] = useState([])


    const [hasError, setErrors] = useState(false)

  async function fetchDirectories() {
    var bearer = 'Bearer ' + cookies.get('securego')
    const response = await fetch(`${REACT_APP_API_URL}/directories/folderbylevel/0`, {
       // headers: { 'Content-Type': 'application/json' },
        //credentials: 'include',
        headers:{
          'Content-Type':'application/json',
          'Authorization': bearer
      },
     // mode: "no-cors"

    })

    response
        .json()
        .then(res => {

          SetDirectories(res)
        })
        .catch(err => setErrors(err));
}

useEffect(() => {

  fetchDirectories();


}, [])




  return (
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar collapse">
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink to={'/admin'} exact className="nav-link">
              Dashboard
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={'/admin/users'} className="nav-link">
              Users
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={'/admin/roles'} className="nav-link">
              Roles
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={'/admin/permissions'} className="nav-link">
              Permissions
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={'/admin/documents'} className="nav-link">
              Directories
            </NavLink>
          </li>
         
          
          {/* {directories.length > 0 && directories.map((d: Folder) => {

          return (
            <li className="nav-item" key={d.id}>
              <NavLink to={`/directories/${d.id}/${d.name}`} className="nav-link">
                {d.name}
              </NavLink>
            </li>
          )
          })} */}

        </ul>

      </div>
    </nav>
  )
}

export default AdminMenu