export class FileViewItem {
    constructor(
        public file_url:string = '',
        public file_ext:string = '',
        public file_type:string = '',
        public file_name:string = '',
       
        //id, doc_id, doc_title, updated_at, created_at, created_by, updated_by, docitem_subs
    ){
        
    }
}