import { combineReducers } from 'redux'
import {setUserReducer}  from './setUserReducer'
import {documentReducer} from './documentReducer'
import { refreshReducer } from './refreshReducer';
import { folderReducer } from './folderReducer';
import { directoryReducer } from './directoryReducer';


export default combineReducers({
    users: setUserReducer,
    documents: documentReducer,
    refreshs: refreshReducer,
    folders: folderReducer,
    directorys: directoryReducer
   
  });
