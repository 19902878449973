import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';
import { Role } from '../../../models/role';
import Cookies from 'universal-cookie';
const cookies = new Cookies();



const {REACT_APP_API_URL} = process.env

const UserEdit = (props: any) => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role_id, setRoleId] = useState('');
    const [roles, setRoles] = useState([]);
    const [redirect, setRedirect] = useState(false);



    useEffect(() => {
        (
            async () => {
                //const response = await axios.get('roles');
                var bearer = 'Bearer ' + cookies.get('securego')
                const response = await fetch(`${REACT_APP_API_URL}/roles`, {
                    //headers: { 'Content-Type': 'application/json' },
                    //credentials: 'include',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    },
                    //mode: "no-cors"
                })

                const data = await response.json();
                setRoles(data);



                // const {data} = await axios.get(`users/${props.match.params.id}`);
              
                const response2 = await fetch(`${REACT_APP_API_URL}/users/${props.match.params.id}`, {
                    //headers: { 'Content-Type': 'application/json' },
                    //credentials: 'include',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    },
                    //mode: "no-cors"
                })

                const data2 = await response2.json();


                setFirstName(data2.first_name);
                setLastName(data2.last_name);
                setEmail(data2.email);
                setRoleId(data2.role.id);
            }
        )()
    }, []);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();


        // await axios.put(`users/${props.match.params.id}`, {
        //     first_name,
        //     last_name,
        //     email,
        //     role_id:Number(role_id)
        // });
        var bearer = 'Bearer ' + cookies.get('securego')
        await fetch(`${REACT_APP_API_URL}/users/${props.match.params.id}`, {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
           // mode: "no-cors",
            body: JSON.stringify({
                first_name,
                last_name,
                email,
                role_id: role_id
            })
        })

        setRedirect(true);
    }

    if (redirect) {
        return <Redirect to="/admin/users" />
    }

    return (
        <AdminWrapper>
            <form onSubmit={submit}>
                <div className="mb-3">
                    <label>First Name</label>
                    <input className="form-control"
                        defaultValue={first_name}
                        onChange={e => setFirstName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>Last Name</label>
                    <input className="form-control"
                        defaultValue={last_name}
                        onChange={e => setLastName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>Email</label>
                    <input className="form-control"
                        defaultValue={email}
                        onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>Role</label>
                    <select className="form-control"
                        value={role_id}
                        onChange={e => setRoleId(e.target.value)}>
                        {roles.map((r: Role) => {
                            return (
                                <option key={r.id} value={r.id}>{r.name}</option>
                            )
                        })}
                    </select>
                </div>

                <button className="btn btn-outline-secondary">Save</button>
            </form>
        </AdminWrapper>
    )
};

export default UserEdit;