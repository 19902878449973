import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';

import { Role } from '../../../models/role';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')


const { REACT_APP_API_URL } = process.env

const Roles = () => {
    const [roles, setRoles] = useState([]);
    useEffect(() => {
        (
            async () => {
                //const {data} = await axios.get('roles');
                var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
                const response = await fetch(`${REACT_APP_API_URL}/roles`, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }

                })

                const data = await response.json();


                setRoles(data);
            }
        )();
    }, []);

    const del = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this record?')) {

            await fetch(`${REACT_APP_API_URL}/roles/${id}`, {
                method: "DELETE",
                headers:{
                    'Content-Type':'application/json',
                    'Authorization': bearer
                }

            })

            setRoles(roles.filter((r: Role) => r.id !== id));

        }
    }
    return (
        <AdminWrapper>
            <div className="pt-3 pb-2 mb-3 border-bottom">
                <Link to="/admin/roles/create" className="btn btn-sm btn-outline-secondary">Add</Link>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>

                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roles.map((role: Role) => {
                            return (
                                <tr key={role.id}>

                                    <td>{role.name}</td>
                                    <td>
                                        <div className="btn-group mr-2">
                                            <Link to={`/admin/roles/${role.id}/edit`} className="btn btn-sm btn-outline-secondary">Edit</Link>
                                            <a href="#" className="btn btn-sm btn-outline-secondary" onClick={() => del(role.id)}>Delete</a>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </AdminWrapper>
    )
}

export default Roles;