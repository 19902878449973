import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { Dispatch, MutableRefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import Modal from 'react-modal';
import { connect, useDispatch } from "react-redux";
import Documentitem from "../../components/Documentitem";
import Wrapper from "../../components/Wrapper";


import { DocumentItem } from "../../models/documentitem";
import { FileItem } from "../../models/fileitem";
import { Navigation } from "../../models/navigation";
import { setDirectoryName } from "../../redux/actions/documentAction";

import { setRefresh } from "../../redux/actions/refreshAction";

import Cookies from 'universal-cookie';
const cookies = new Cookies();



const { REACT_APP_API_URL, REACT_APP_LOCAL_URL } = process.env

const Directories = (props: any) => {
    const dispatch = useDispatch();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [searchmodalIsOpen, setSearchIsOpen] = useState(false);
    const [uploadmodalIsOpen, setUploadIsOpen] = useState(false);
    //Parameter

    const [documentName, SetDocumentName] = useState('')
    const [documentType, SetDocumentType] = useState('')
    const [folderID, SetFolderID] = useState("")
    const [folderName, SetFolderName] = useState("")
    const [refreshData, SetRefreshData] = useState(false)

    //search
    const [keyword, SetKeyword] = useState('')
    const [sendkeyword, SetSendKeyword] = useState('')
    const [searchStatus,SetSearchStatus] = useState(false)

    const [hasErrorDoc, setDocErrors] = useState(false)

    const allDocumentsSelected = props.documentsFromSelected

    //upload
    const fileInputRef = useRef(null) as any;
    const [selectfiles, setSelectFiles] = useState([] as FileItem[])
    const [isupload, setIsUpload] = useState(false);

    //navigate
    const [navigations, SetNavigations] = useState<Navigation[]>([]);


    const [authorizeDirectoryStatus,SetAuthorizeDirectoryStatus] = useState(false)


    useEffect(() => {

        SetFolderID(props.match.params.id)
        SetFolderName(props.match.params.name)

        checkAuthorizeDirectory(props.match.params.id)
        props.setDirectoryName(props.match.params.name)

    }, [])


    const openModal = () => {

        setIsOpen(true);
    }
    const closeModal = () => {

        setIsOpen(false);
    }

    const searchopenModal = () => {

      
        setSearchIsOpen(true);
    }
    const searchcloseModal = () => {

        setSearchIsOpen(false);

    }

    const uploadopenModal = () => {

        setUploadIsOpen(true);
    }
    const uploadcloseModal = () => {

        setUploadIsOpen(false);
    }

    const createFolder = (e: SyntheticEvent) => {

        e.preventDefault();


        //SetDocumentType("folder")

        createDocument();

    }

    const handleSearchDocument = (e: any) => {
       
        e.preventDefault()

        SetSendKeyword(keyword);
        SetSearchStatus(true)
        searchcloseModal();


    }
    const onKeyUp = (e: any) => {
        if (e.charCode === 13) {
            e.preventDefault()

            SetSendKeyword(keyword);
            SetSearchStatus(true)
            searchcloseModal();
        }
       


    }

    async function checkAuthorizeDirectory(_folderId: string) {
        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
        const response = await fetch(`${REACT_APP_API_URL}/directories/authorize/${_folderId}`, {
            method: "GET",
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            }
        })

        response
            .json()
            .then(res => {
                    if(res.message === "success"){
                        SetAuthorizeDirectoryStatus(true)
                    }else{
                        SetAuthorizeDirectoryStatus(false)
                    }
                    
                    console.log("checkAuthorizeDirectory")
                    console.log(res)
            })
            .catch(err => setDocErrors(err));
    }

    async function createDocument() {


        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
        const response = await fetch(`${REACT_APP_API_URL}/directories/createdocument`, {
            method: "POST",
            //headers: { 'Content-Type': 'application/json' },
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            body: JSON.stringify({
                name: documentName.trim(),
                type: "folder",
                folder_id: folderID,
                directory_path: getDirctoryPath()
            }),
            //credentials: 'include',
        })

        response
            .json()
            .then(res => {



                //SetRefreshData(true)
                dispatch(setRefresh(`create ${props.match.params.id} ${documentName}`));
                closeModal()
                alert(res.message)


            })
            .catch(err => setDocErrors(err));
    }

    async function deleteDocument() {

        let num = 0
        if (allDocumentsSelected.documents.length > 0) {
            await allDocumentsSelected.documents.map(async (d: DocumentItem) => {
                var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
                const response = await fetch(`${REACT_APP_API_URL}/directories/deletedocument`, {
                    method: "POST",
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    },
                    //mode: "no-cors",
                    //headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        filename: d.name,
                        id: d.id,
                        doc_type: d.doc_type,
                        directory_path: getDirctoryPath()
                    }),
                    //credentials: 'include',
                })
                dispatch(setRefresh(`delete id:$${d.id}`));
                response
                    .json()
                    .then(res => {
                        num = num + 1
                        if (num === allDocumentsSelected.documents.length) {
                            alert("Delete Success!")
                        }
                        //console.log(res.message)


                    })
                    .catch(err => setDocErrors(err));
            })

        } else {
            alert("Please select document before!")
        }






    }
    function getDirctoryPath() {
        console.log(navigations)
        var directoryPath: string = ""
       

        navigations.map((d: Navigation) => {
            directoryPath = directoryPath +"/"+ d.name 
        })

        if (directoryPath === "") {
            directoryPath = "/"+ folderName
        }

        //alert(directoryPath)

        return directoryPath

    }



    const handleDeleteDocument = async () => {


        await deleteDocument()




    }
    const handleUploadDocument = async (e: SyntheticEvent) => {
        e.preventDefault()

        console.log(navigations)
        var directoryPath: string = ""
        navigations.map((d: Navigation) => {
            directoryPath = directoryPath +"/"+ d.name 
        })

        if (directoryPath === "") {
            directoryPath = "/"+ folderName
        }


        await upload(directoryPath)



    }
    const upload = async (directoryPath: string) => {


        setIsUpload(true); //progress


        if (selectfiles.length === 0) {
            alert('Please choose attach file')
            return ''
        }

        for (let i = 0; i < selectfiles.length; i++) {
            const formData = new FormData();
            formData.append('folder_id', folderID);
            //formData.append('folder_name', folderName);
            formData.append('directory_path', directoryPath);

            formData.append(`file_input`, selectfiles[i].file, selectfiles[i].name);
            var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)

            const response = await fetch(`${REACT_APP_API_URL}/directories/uploaddocument`, {
                method: 'POST',
                //credentials: 'include',
                headers:{
                    //'Content-Type':'application/json',
                    'Authorization': bearer
                },
               // mode: "no-cors",
                body: formData,

            })
        }

        setIsUpload(false); //progress
        setSelectFiles([])
        fileInputRef.current.value = ''

        alert("Uploaded completed!")
        dispatch(setRefresh(`upload ${Date.now()}`));
        uploadcloseModal()



    }
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //setImages([...images, ...e.target.files]);
        const files: FileList | null = e.target.files;
        if (!files) return;

        let currentFiles: FileItem[] = []

        for (let i = 0; i < files.length; i++) {
            currentFiles.push({
                file: files[i],
                name: files[i].name,
            });

        }
        setSelectFiles(currentFiles)

    };

    const setFolderByClick = async (_id: string, _name: string) => {
        SetFolderID(_id)
        SetFolderName(_name)
        SetKeyword("")
    }
    const addNavigateMain = async (data: any) => {
        SetNavigations(data)
    }

    return (
        <>
            <Wrapper>
                
                <div className="container">
                    <div className="row">
                        <div className="col">
                           
                        {props.user.role.name === "editor" && authorizeDirectoryStatus === true || props.user.role.name === "admin" && authorizeDirectoryStatus === true ? (
                               <div className="d-flex flex-row bd-highlight mb-3">
                               <div className="p-2 bd-highlight">
                                  
                                   <button type="button"

                                       style={{
                                           borderRadius: '40px',
                                           border: '2px solid #DDDDDD',
                                           color: '#888888'

                                       }}
                                       className="btn" onClick={() => openModal()}>
                                       <FontAwesomeIcon icon={["fas", "upload"]} /><span style={{ color: "white" }}>_</span>NEW<span style={{ color: "white" }}>_</span>
                                   </button>
                               </div>
                               <div className="p-2 bd-highlight">
                                   <button type="button"
                                       style={{
                                           borderRadius: '40px',
                                           border: '2px solid #DDDDDD',
                                           color: '#888888'

                                       }}
                                       className="btn" onClick={() => uploadopenModal()}>
                                       <FontAwesomeIcon icon={["fas", "upload"]} />Upload 
                                   </button>
                               </div>
                               <div className="p-2 bd-highlight">
                                   <button type="button" className="btn"
                                       style={{
                                           borderRadius: '40px',
                                           border: '2px solid #DDDDDD',
                                           color: '#888888'

                                       }}
                                       onClick={() => handleDeleteDocument()}>
                                       <FontAwesomeIcon icon={["fas", "trash"]} />Delete
                                   </button>
                               </div>
                           </div>
                        ):(
                            <div></div>
                        )}
                         


                        </div>
                        <div className="col">
                            <div className="d-flex flex-row-reverse bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <button type="button"
                                        style={{
                                            borderRadius: '40px',
                                            border: '2px solid #DDDDDD',
                                            color: '#888888',


                                        }}
                                        className="btn" onClick={() => searchopenModal()}>
                                        <FontAwesomeIcon icon={["fas", "search"]} />Search
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">

                        </div>
                    </div>


                    <Documentitem folderid={folderID} foldername={folderName} setfolderbyclick={setFolderByClick} 
                    keyword={sendkeyword} searchstatus={searchStatus}
                        addnavigatemain={addNavigateMain} />

                </div>


                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}

                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.3)'
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            background: 'white',
                            border: '0px solid black'
                        },
                    }}
                    contentLabel="Example Modal"

                >

                    <div className="card mb-3 border-0" >
                        <div className="card-header bg-transparent border-0">
                            <div className="container">

                                <div className="row">
                                    <div className="col-11" style={{fontSize:'20px'}}><b>Create a Folder</b></div>
                                    <div className="col-1">
                                        <button className="btn" onClick={closeModal}>X</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body text-success border-0">


                            <input type="text" 
                             placeholder="Enter your folder name"
                            onChange={(e) => SetDocumentName(e.target.value)} autoFocus className="form-control" style={{ 
                                background: '#eee',
                                borderBottom:'10px solid blue;',
                                borderBottomStyle:'double'
                                }} />
                        </div>
                        <div className="card-footer bg-transparent border-0">

                            <div className="d-flex justify-content-end">
                                <button className="btn" style={{background:'#343CE9',color:'white'}} onClick={(e) => createFolder(e)}>Create</button>

                            </div>
                        </div>
                    </div>

                </Modal>

                {/* search */}
                <Modal
                    isOpen={searchmodalIsOpen}
                    onRequestClose={searchcloseModal}

                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.3)'
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            background: 'white',
                            border: '0px solid black'
                        },
                    }}
                    contentLabel="Example Modal"

                >

                    <div className="card mb-3 border-0" >
                        <div className="card-header bg-transparent border-0">
                            <div className="container">

                                <div className="row">
                                    <div className="col-11" style={{fontSize:'20px'}}><b>Keyword</b></div>
                                    <div className="col-1">
                                        <button className="btn" onClick={searchcloseModal}>X</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body text-success border-0">


                            <input type="text" 
                             placeholder="Enter document name"
                             onKeyPress={(e) => onKeyUp(e)}
                            onChange={(e) => SetKeyword(e.target.value)} autoFocus className="form-control" style={{ background: '#eee' }} />
                        </div>
                        <div className="card-footer bg-transparent border-0">

                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" style={{background:'#343CE9',color:'white'}} onClick={(e) => handleSearchDocument(e)}>Search</button>

                            </div>
                        </div>
                    </div>

                </Modal>


                {/* upload file */}
                <Modal
                    isOpen={uploadmodalIsOpen}
                    onRequestClose={uploadcloseModal}

                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.3)'
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            background: 'white',
                            border: '0px solid black'
                        },
                    }}
                    contentLabel="Example Modal"

                >

                    <div className="card mb-3 border-0" >
                        <div className="card-header bg-transparent border-0">
                            <div className="container">

                                <div className="row">
                                    <div className="col-11" style={{fontSize:'20px'}}><b>File</b></div>
                                    <div className="col-1">
                                        <button className="btn" onClick={uploadcloseModal}>X</button>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body text-success border-0">


                            <input className="form-control" multiple
                                type="file"
                                ref={fileInputRef}
                                onChange={onFileChange}
                            />
                        </div>
                        <div className="card-footer bg-transparent border-0">

                            <div className="d-flex justify-content-end">
                                {/* <button className="btn btn-primary" onClick={(e) => handleUploadDocument(e)}>Upload</button> */}
                                {isupload ?

                                    <button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Uploading...
                                    </button>
                                    :
                                    <button className="btn btn-primary" style={{background:'#343CE9',color:'white'}} onClick={(e) => handleUploadDocument(e)}>Upload</button>

                                }
                            </div>
                        </div>
                    </div>

                </Modal>
            </Wrapper>

        </>
    )

}

const mapStateToProps = (state: any) => {
    return {
        documentsFromSelected: state.documents,
        directorypathFromStore: state.documents.directorypath,
        user: state.users.user
    };
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setRefresh: (refresh: string) => dispatch(setRefresh(refresh)),
        setDirectoryName: (directoryname: string) => dispatch(setDirectoryName(directoryname)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Directories);
//export default Directories;