import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';
import { Role } from '../../../models/role';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')

const {REACT_APP_API_URL} = process.env

const UserCreate = () => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role_id, setRoleId] = useState('');
    const [roles, setRoles] = useState([]);
    const [redirect, setRedirect] = useState(false);


    useEffect(() => {
        (
            async () => {
               // const { data } = await axios.get('roles');
               var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
                const response = await fetch(`${REACT_APP_API_URL}/roles`,{
                    //headers:{'Content-Type':'application/json'},
                    //credentials: 'include',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }

                })
        
               const data = await response.json();
                setRoles(data);

            }
        )()
    }, []);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        console.log(first_name);
        console.log(last_name);
        console.log(email);
        console.log(role_id);


        // await axios.post('users', {
        //     first_name,
        //     last_name,
        //     email,
        //     role_id: Number(role_id)
        // });

        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
        await fetch(`${REACT_APP_API_URL}/users`, {
            method: 'POST',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            body: JSON.stringify({
                first_name,
                last_name,
                email,
                role_id: role_id
            })
        })

        setRedirect(true);
    }

    if (redirect) {
        return <Redirect to="/admin/users" />
    }

    return (
        <AdminWrapper>
            <form onSubmit={submit}>
                <div className="mb-3">
                    <label>First Name</label>
                    <input className="form-control" onChange={e => setFirstName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>Last Name</label>
                    <input className="form-control" onChange={e => setLastName(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>Email</label>
                    <input className="form-control" onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label>Role</label>
                    <select className="form-control" onChange={e => setRoleId(e.target.value)}>
                    <option value=""></option>
                        {roles.map((r: Role) => {
                            return (
                                <option key={r.id} value={r.id}>{r.name}</option>
                            )
                        })}
                    </select>
                </div>

                <button className="btn btn-outline-secondary">Save</button>
            </form>
        </AdminWrapper>
    )
};

export default UserCreate;