// import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import Nav from './components/Nav';
// import Menu from './components/Menu';



import Dashboard from './pages/Dashboard';
import { BrowserRouter, Route } from 'react-router-dom';
import Users from './pages/admin/users/Users';
import Register from './pages/Register';
import Login from './pages/Login';
import UserCreate from './pages/admin/users/UserCreate';
import UserEdit from './pages/admin/users/UserEdit';
import Roles from './pages/admin/roles/Roles';
import RoleCreate from './pages/admin/roles/RoleCreate';
import RoleEdit from './pages/admin/roles/RoleEdit';
import Permissions from './pages/admin/permissions/Permissions';
import PermissionCreate from './pages/admin/permissions/PermissionCreate';
import PermissionEdit from './pages/admin/permissions/PermissionEdit';

import Profile from './pages/Profile';


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import Directories from './pages/directories/Directories';
import DocumentViewer from './pages/directories/DocumentViewer';
import Admin from './pages/admin/Admin';
import Admins from './pages/admin/Admin';
import AdminLogin from './pages/admin/AdminLogin';
import AdminPermission from './pages/admin/documents/AdminDocuments';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminDocuments from './pages/admin/documents/AdminDocuments';
import AdminRegister from './pages/admin/AdminRegister';
import AdminDocumentEdit from './pages/admin/documents/AdminDocumentEdit';
import AdminDocumentCreate from './pages/admin/documents/AdminDocumentCreate';
import CRMAuth from './pages/crm/Auth';
import { useEffect } from 'react';
import { initGA } from '.';
import SSO from './pages/SSO';
import SSOcheck from './pages/SSOcheck';




library.add(fas, fab, far);

function App() {

  useEffect(() => { initGA(); }, []);

  return (
    <div className="App">
      <BrowserRouter>
         
        <Route path={'/'} exact component={AdminDashboard} />
        <Route path={'/profile'} exact component={Profile}/>
        <Route path={'/register'} component={Register} />
        <Route path={'/login'} exact component={Login} />
        <Route path={'/sso'} exact component={SSO} />
        <Route path={'/ssocheck/:topic'} exact component={SSOcheck} />
       

       

        
        {/* <Route path={'/products'} exact component={Products}/>
        <Route path={'/products/create'} exact component={ProductCreate}/>
        <Route path={'/products/:id/edit'} exact component={ProductEdit}/>


        <Route path={'/orders'} exact component={Orders}/>

        
        
       
         {/* directory */}
         <Route path={'/directories/:id/:name'} exact component={Directories}/>
         <Route path={'/directories/documentviewer'} exact component={DocumentViewer}/>

          {/* admin */}
          <Route path={'/admin/register'} exact component={AdminRegister}/>
         <Route path={'/admin/login'} exact component={AdminLogin}/>
         <Route path={'/admin'} exact component={Admin}/>
         <Route path={'/admin/documents'} exact component={AdminDocuments}/>
         <Route path={'/admin/documents/create'} component={AdminDocumentCreate} />
         <Route path={'/admin/documents/:id/edit'} component={AdminDocumentEdit} />
         <Route path={'/admin/users'} exact component={Users} />
         <Route path={'/admin/users/create'} component={UserCreate} />
         <Route path={'/admin/users/:id/edit'} component={UserEdit} />
         <Route path={'/admin/roles'} exact component={Roles} />
         <Route path={'/admin/roles/create'} component={RoleCreate} />
         <Route path={'/admin/roles/:id/edit'} exact component={RoleEdit}/>
         <Route path={'/admin/permissions'} exact component={Permissions} />
         <Route path={'/admin/permissions/create'} component={PermissionCreate} />
         <Route path={'/admin/permissions/:id/edit'} exact component={PermissionEdit}/>

        
         {/* CRM */}
         <Route path={'/crm/auth'} exact component={CRMAuth}/>

      </BrowserRouter>
    </div>
  );
}

export default App;
