import axios from 'axios';
import React, { SyntheticEvent, Component, Dispatch, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import Wrapper from '../components/Wrapper';
import Cookies from 'universal-cookie';
import { User } from '../models/user';
import { connect } from "react-redux";
import { setUser } from '../redux/actions/setUserAction';
import { setDirectoryName } from '../redux/actions/documentAction';
import { useCookies } from 'react-cookie';


const cookies = new Cookies();


const { REACT_APP_API_URL } = process.env


interface UserCookie{
    id: number
    email: string,
    exp: number,
    first_name: string,
    last_name: string,

}

const SSOCheck = (props: any) => {

    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [cookiesNew, setCookieNew] = useCookies(['securetoken']);
    const [cookiesGo, setCookieNewGo] = useCookies(['securego']);
    const [topic, setTopic] = useState("")

    //   console.log("sso")
    //   console.log(cookies.get('sso'))



    const authenBySSO = async () => {
        try {
            let cookie_sso = cookies.get('sso')
            let email = cookie_sso['email']
            let exp = cookie_sso['exp']
            let first_name = cookie_sso['first_name']
            let last_name = cookie_sso['last_name']
            let companycode = cookie_sso['company code']

            const response = await fetch(`${REACT_APP_API_URL}/sso`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    first_name: first_name,
                    last_name: last_name
                })
            })
            const data = await response.json();

            var user = new User()
            user.id = data.user.id
            user.first_name = data.user.first_name
            user.last_name = data.user.last_name
            user.email = data.user.email
            user.role = data.user.role


            props.setUser(data.user);

            localStorage.setItem("securego", JSON.stringify(data.token));
            setCookieNewGo('securego', data.token, { path: '/', domain: '.ispacezone.com' });

            console.log("data.user")
            console.log(data.user)

            setTopic(props.match.params.topic)
            setRedirect(true);


        } catch (error: any) {
            if (localStorage.getItem("securego") !== undefined) {

                let cookie_sso = JSON.parse(localStorage.getItem('securego') as any)
                let email = cookie_sso['email']
                let exp = cookie_sso['exp']
                let first_name = cookie_sso['first_name']
                let last_name = cookie_sso['last_name']
                let companycode = cookie_sso['company code']

                const response = await fetch(`${REACT_APP_API_URL}/sso`, {
                    method: 'POST',

                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: email,
                        first_name: first_name,
                        last_name: last_name
                    })
                })
                const data = await response.json();

                var user = new User()
                user.id = data.user.id
                user.first_name = data.user.first_name
                user.last_name = data.user.last_name
                user.email = data.user.email
                user.role = data.user.role

                console.log(data.user)
                props.setUser(data.user);

                localStorage.setItem("securego", JSON.stringify(data.token));
                setCookieNewGo('securego', data.token, { path: '/', domain: '.ispacezone.com' });




                setTopic(props.match.params.topic)
                setRedirect(true);
            }else{
                alert("Please Log in at ispacezone.com again")
                window.open("https://ispacezone.com", "_self");
            }
           
        }


    }

    useEffect(() => {
        authenBySSO()
    }, [])




    if (redirect) {
        if (topic === "iso") {
            return <Redirect to={'/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO'} />;
        } else if (topic === "scgguideline") {
            return <Redirect to={'/directories/241fa0fb-323b-4e5d-9774-d32e7bd48373/SCG%20Guideline'} />;
        } else if (topic === "risk") {
            return <Redirect to={'/directories/53c1a606-3bfd-42b3-9fee-34771c11e011/Risk'} />;
        } else if (topic === "antitrust") {
            return <Redirect to={'/directories/a3123779-b827-4692-a9b7-d5d56a76e071/Antitrust'} />;
        } else {
            return <Redirect to={'/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO'} />;
        }


    }


    return (

        <div>
            SSO
        </div>

    )
}

const mapStateToProps = (state: any) => {
    return {
        user: state.users.user,
        directoryname: state.directorys.directoryname
    };
}


const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setUser: (user: User) => dispatch(setUser(user)),
        setDirectoryName: (directoryname: string) => dispatch(setDirectoryName(directoryname)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SSOCheck);