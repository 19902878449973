import { DocumentItem } from "../../models/documentitem";
import { FileViewItem } from "../../models/fileviewitem";
import { Navigation } from "../../models/navigation";





//2.Create Reducer
export const directoryReducer = (state = {directoryname:""}, action: { type: string, payload: any }) => { //DocumentItem
    switch (action.type) {
        case "SET_DIRECTORY_NAME":
            return {
                ...state,
                directoryname: action.payload
            }

        default:
            return state;
    }
}



