
 


//2.Create Reducer
export const refreshReducer = (state = {refresh: false }, action: { type: string, payload: string }) => {
    switch (action.type) {
        case "SET_REFRESH":
            return {
                ...state,
                refresh: action.payload
            }
        default:
            return state;
    }
}







// const initialState: TodoState = {
// 	todos: [],
// };

// export function todoReducer(state = initialState, action: TodoActionInterface): TodoState {
// 	switch (action.type) {
// 		case TodoActionType.ADD_TODO:
// 			return { todos: [...state.todos, action.payload] };
// 		case TodoActionType.DELETE_TODO:
// 			return { todos: state.todos.filter((todo, index) => index !== action.payload) };
// 		case TodoActionType.CHECK_TODO:
// 			return {
// 				todos: state.todos.map((todo, index) =>
// 					index === action.payload ? { todo: todo.todo, isCheck: !todo.isCheck } : todo
// 				),
// 			};
// 		default:
// 			return state;
// 	}
// }




