import axios from 'axios';
import React, { SyntheticEvent, useState } from 'react';
import { Redirect } from 'react-router';
import Wrapper from '../components/Wrapper';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const { REACT_APP_API_URL } = process.env

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);




  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    //  const {data} = await axios.post('login',{
    //       email,
    //       password
    //   });
    // var bearer = 'Bearer ' + cookies.get('securego')
    //console.log(bearer)
    const response = await fetch(`${REACT_APP_API_URL}/login`, {
      method: 'POST',
      //credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password
      })
    })

    const data = await response.json();
    console.log(data)


    if (data.message === "success") {
      cookies.set("securego", data.token)
      setRedirect(true);
    } else {
      alert("User/Password incorrect")
    }

    //console.log(data)

  }

  if (redirect) {
    return <Redirect to={'/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO'} />;
  }
  if(cookies.get('securego')){
    return <Redirect to={'/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO'} />;
  }

  return (

    <main className="form-signin">
      <form onSubmit={submit}>
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <input type="email" className="form-control" placeholder="Email" required
          onChange={e => setEmail(e.target.value)}
        />
        <input type="password" className="form-control" placeholder="Password" required
          onChange={e => setPassword(e.target.value)}
        />


        <button className="w-100 btn btn-lg btn-primary" type="submit">Submit</button>

      </form>
    </main>

  )
}

export default Login;