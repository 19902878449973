import React, { useEffect } from 'react';
import AdminWrapper from '../../components/AdminWrapper';
import * as c3 from 'c3';
import axios from 'axios';

const {REACT_APP_API_URL} = process.env


const AdminDashboard = () => {
    // useEffect(() => {
    //     (

    //         async () => {
    //             const chart = c3.generate({
    //                 bindto: '#chart',
    //                 data: {
    //                     x: 'x',
    //                     columns: [
    //                         ['x'],
    //                         ['Sales'],
    //                     ],
    //                     types: {
    //                         Sales: 'bar'
    //                     }
    //                 },
    //                 axis: {
    //                     x: {
    //                         type: 'timeseries',
    //                         tick: {
    //                             format: '%Y-%m-%d'
    //                         }
    //                     }
    //                 }
    //             });

    //             // const {data} = await axios.get('chart',{
    //             //     headers:{'Content-Type':'application/json'},
    //             //     withCredentials: true
    //             // });

    //             const response = await fetch(`${REACT_APP_API_URL}/chart`,{
    //                 headers:{'Content-Type':'application/json'},
    //                 credentials: 'include',
    //             })

    //            const data = await response.json();

             

                
                
    //             if(data){
    //             chart.load({
    //                 columns: [
    //                     ['x', ...data.map((r: any) => r.data)],
    //                     ['Sales', ...data.map((r: any) => r.sum)],
    //                 ]
    //             })
    //         }
    //         }
    //     )()
    // },[]);

    return (
        <AdminWrapper>
            <h2>Administrator</h2>

            <div id="chart"></div>
        </AdminWrapper>
    )

}
export default AdminDashboard
