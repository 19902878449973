





export const setRefresh = (refresh: string) => {
    return {
        type: 'SET_REFRESH',
        payload: refresh
    }
}


