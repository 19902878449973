import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';
import { Permission } from '../../../models/permission';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')

const {REACT_APP_API_URL} = process.env


const RoleEdit = (props: any) => {
    const [permissions,setPermissions] = useState([]);
    const [selected,setSelected] = useState([] as number[])
    const [name,setName] = useState('');
    const [redirect,setRedirect] = useState(false);

    useEffect(() => {
        (
            async () => {
               
                var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
                const response = await fetch(`${REACT_APP_API_URL}/permissions`,{
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }
                   

                })
                const data = await response.json();

                setPermissions(data);

               // const {data} = await axios.get(`roles/${props.match.params.id}`);

                const response2 = await fetch(`${REACT_APP_API_URL}/roles/${props.match.params.id}`,{
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }
                   

                })
                const data2 = await response2.json();

                setName(data2.name);

                setSelected(data2.permissions.map((p:Permission) => p.id));
            }
        )();
    },[])


    const check = (id: number) => {
        if(selected.some(s => s === id)){
            setSelected(selected.filter(s => s !== id));
            return;
        }

        setSelected([...selected,id]);
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        console.log(selected)

        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)

        await fetch(`${REACT_APP_API_URL}/roles/${props.match.params.id}`, {
            method: 'PUT',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            body: JSON.stringify({
                name: name,
                permissions: selected.map(num => {return num.toString()})  //go error
            })
        })
           
        setRedirect(true);
    }

    if(redirect){
        return <Redirect to="/admin/roles"/>;
    }

    return (
        <AdminWrapper>
            <form onSubmit={submit}>
                <div>
                   <label className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input className="form-control" defaultValue={name} onChange={e => setName(e.target.value)}/>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label  className="col-sm-2 col-form-label">Permissions</label>
                    <div className="col-sm-10">
                        {permissions.length > 0 && permissions.map((p:Permission) => {
                            return(
                                <div className="form-check form-check-inline col-3" key={p.id}>
                                    <input type="checkbox" className="form-check-input" 
                                    value={p.id}
                                    checked={selected.some(s => s === p.id)}
                                    onChange={() => check(p.id)}
                                    />
                                    <label className="form-check-label">{p.name}</label>
                                </div>
                            )
                        })}
                       
                    </div>
                </div>

                <button className="btn btn-outline-secondary">Save</button>
            </form>
        </AdminWrapper>
    )
}

export default RoleEdit;