import { DocumentItem } from "../../models/documentitem";
import { FileViewItem } from "../../models/fileviewitem";
import { Navigation } from "../../models/navigation";


interface DocumentState {
    documents: [],
    fileviewer: FileViewItem,
    directoryname: string

}

const initialState: DocumentState = {
    documents: [],
    fileviewer: new FileViewItem(),
    directoryname: ""

}


//2.Create Reducer
export const documentReducer = (state = initialState, action: { type: string, payload: any }) => { //DocumentItem
    switch (action.type) {
        case "ADD_DOCUMENT":
            return {
                ...state,
                documents: [...state.documents, action.payload]
            }
        case "DELETE_DOCUMENT":
            return {
                ...state,
                documents: state.documents.filter((doc: DocumentItem) => doc.id !== action.payload.id)
            }
        case "SET_DOCUMENT_VIEWER":
            return {
                ...state,
                fileviewer: action.payload
            }

        case "SET_DIRECTORY_NAME":
            return {
                ...state,
                directoryname: action.payload
            }

        default:
            return state;
    }
}



