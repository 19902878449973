import React from "react";
import Logo from "../assets/Light.png"


const Footer = () => {


    return (
        <div className="footer-bg text-center align-middle">
            <br/>
            <br/>
            <br/>
            <br/>
          <div className="mx-auto" style={{width: "200px"}}>
          <img className="d-block mx-auto mb-4 mt-10"  src={Logo} alt="" ></img>
</div>
           
        </div>

    )
}

export default Footer;