import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';
import { Permission } from '../../../models/permission';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')


const {REACT_APP_API_URL} = process.env


const RoleEdit = (props: any) => {
    const [permissions,setPermissions] = useState([]);
    const [selected,setSelected] = useState([] as number[])
    const [name,setName] = useState('');
    const [redirect,setRedirect] = useState(false);

    useEffect(() => {
        (
            async () => {
                //const response = await axios.get('permissions');
                const response2 = await fetch(`${REACT_APP_API_URL}/permissions/${props.match.params.id}`,{
                    //headers:{'Content-Type':'application/json'},
                    //credentials: 'include',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }
                   

                })
                const data2 = await response2.json();

                setName(data2.name);
             
            }
        )();
    },[])


    

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        console.log(selected)

       

        await fetch(`${REACT_APP_API_URL}/permissions/${props.match.params.id}`, {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            body: JSON.stringify({
                name: name,
                
            })
        })
           
        setRedirect(true);
    }

    if(redirect){
        return <Redirect to="/admin/permissions"/>;
    }

    return (
        <AdminWrapper>
            <form onSubmit={submit}>
                <div>
                   <label className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input className="form-control" defaultValue={name} onChange={e => setName(e.target.value)}/>
                    </div>
                </div>

               

                <button className="btn btn-outline-secondary">Save</button>
            </form>
        </AdminWrapper>
    )
}

export default RoleEdit;