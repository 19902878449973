import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { User } from "../models/user";
import { connect } from "react-redux";
import logo from "../logo.png"
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')

const { REACT_APP_API_URL,REACT_APP_ENV,REACT_APP_DOMAIN_NAME } = process.env


const Nav = (props: { user: User }) => {


  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);


  const [click, setClick] = useState(false)
  const handleClick = () => {

    setClick(!click)
    setClickInspire(false)
    setClickInformation(false)
    setClickIdea(false)
  };

  const [clickInspire, setClickInspire] = useState(false)
  const handleClickInspire = () => {

    setClick(false)
    setClickInspire(!clickInspire)
    setClickInformation(false)
    setClickIdea(false)
  };

  const [clickInformation, setClickInformation] = useState(false)
  const handleClickInformation = () => {

    setClick(false)
    setClickInspire(false)
    setClickInformation(!clickInformation)
    setClickIdea(false)
  };

  const [clickIdea, setClickIdea] = useState(false)
  const handleClickIdea = () => {

    setClick(false)
    setClickInspire(false)
    setClickInformation(false)
    setClickIdea(!clickIdea)
  };

  const handleClickToCustSat = () => {
    
    window.open("https://custsat.ispacezone.com/SSO?token="+JSON.parse(localStorage.getItem("securego") as any)+"&email="+props.user.email, "_blank");    
  
  }

  const logout = async () => {
    // await axios.post("logout", {

    // })
    localStorage.clear()

    await fetch(`${REACT_APP_API_URL}/logout`, {
      method: 'POST',
      //headers: { 'Content-Type': 'application/json' },
      //credentials: 'include',
      headers:{
        'Content-Type':'application/json',
        'Authorization': bearer
    },
    mode: "no-cors"

    })
    // cookies.remove("user");
    // localStorage.removeItem('ispacezone-jwt')
    // localStorage.removeItem('groupname-jwt')
    // localStorage.removeItem('accessToken')
    // localStorage.removeItem('ispacezone-user')
    // localStorage.removeItem('ispacezone-jwt')
    // localStorage.removeItem('accessToken')


    

    if(REACT_APP_ENV === 'dev'){
      return <Redirect to="/login" />
  }else{
      window.open(REACT_APP_DOMAIN_NAME, "_self");
  }


  }


  return (
    <>
      {/* <nav className="navbar sticky-top bg-white flex-md-nowrap p-0">
      <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">
      <img src={logo} alt="Logo" />
      </a>

      <ul className="my-2 my-md-0 mr-md-3">
        
      
          {props.user.name}
        <Link to="/login" className="p-2 text-decoration-none"
          onClick={logout}
        >Sign out</Link>
      </ul>
     
    </nav> */}



      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">
            <img src={logo} alt="Logo" />
          </a>
          <button className="navbar-toggler" aria-expanded={!isNavCollapsed ? true : false} type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-label="Toggle navigation"
            onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon" />
          </button>

          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item" style={{"fontWeight":"bold","color":"black"}}>
                <a className="nav-link active" aria-current="page" href="https://ispacezone.com/landingpage">Landing Page</a>
              </li>
              <li className="nav-item" style={{"fontWeight":"bold","color":"black"}}>
                <a className="nav-link" href="https://ispacezone.com/incubationlandingpage">Incubation</a>
              </li>
              <li className="nav-item dropdown" style={{"fontWeight":"bold","color":"black"}}>
                <a onClick={handleClickInspire}  className={`nav-link dropdown-toggle ${clickInspire ? 'show' : ''}`} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
                aria-expanded={!clickInspire ? true : false}>
                  Inspire
              </a>
                <ul style={{'background':'#FAC51E'}} className={`dropdown-menu ${clickInspire ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://ispacezone.com/inspire">New Issues</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/we-transform">WeTransform</a></li>
                 
                </ul>
              </li>
              <li className="nav-item dropdown" style={{"fontWeight":"bold","color":"black"}}>
                <a onClick={handleClickInformation}  className={`nav-link dropdown-toggle ${clickInformation ? 'show' : ''}`} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
                aria-expanded={!clickInformation ? true : false}>
                  Information
              </a>
                <ul style={{'background':'#FAC51E'}} className={`dropdown-menu ${clickInformation ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://ispacezone.com/TrainingMaterials">Innovation Materials</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/internal-new">Internal News</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/BooksSuggestion">Book Suggestion</a></li>
                  <li><a className="dropdown-item" href="https://infosearch.ispacezone.com">Info Search (External News)</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown" style={{"fontWeight":"bold","color":"black"}}>
                <a onClick={handleClickIdea}  className={`nav-link dropdown-toggle ${clickIdea ? 'show' : ''}`} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
                aria-expanded={!clickIdea ? true : false}>
                  Idea
              </a>
                <ul style={{'background':'#FAC51E'}} className={`dropdown-menu ${clickIdea ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://ispacezone.com/PageIdea">Idea</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/idealanding">Idea Tank</a></li>
                  <li><a className="dropdown-item" href="https://ispacezone.com/addnewidea">Add Idea Tank</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown" style={{"fontWeight":"bold","color":"black"}}>
                <a onClick={handleClick}  className={`nav-link dropdown-toggle ${click ? 'show' : ''}`} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
                aria-expanded={!click ? true : false}>
                  Compliance
              </a>
                <ul style={{'background':'#FAC51E'}} className={`dropdown-menu ${click ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://ispacezone.com/law-landing">Law & Regulation</a></li>
                  <li><a className="dropdown-item" href="#" onClick={() => alert("In progress")}>PDPA Database</a></li>
                  <li><a className="dropdown-item" href="#" onClick={() => alert("In progress")}>CAR</a></li>
                  <li><a className="dropdown-item" href="https://app.ispacezone.com/directories/53c1a606-3bfd-42b3-9fee-34771c11e011/Risk">Risk</a></li>
                  <li><a className="dropdown-item" href="https://app.ispacezone.com/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO">ISO</a></li>
                  <li><a className="dropdown-item" href="https://app.ispacezone.com/directories/241fa0fb-323b-4e5d-9774-d32e7bd48373/SCG%20Guideline">SCG Guideline</a></li>
                  <li><a className="dropdown-item" href="https://app.ispacezone.com/directories/a3123779-b827-4692-a9b7-d5d56a76e071/Antitrust">Antitrust</a></li>
                  <li><a className="dropdown-item" onClick={() => handleClickToCustSat()}>Cust Satisfaction</a></li>
                </ul>
              </li>

            </ul>
            <span className="navbar-text">
              {props.user.first_name}[{props.user.role.name}]
              <Link to="/login" className="p-2 text-decoration-none"
                onClick={logout}
              >Sign out</Link>
            </span>
          </div>
        </div>
      </nav>





    </>
  )
}



export default connect(
  (state: any) => {
    return {
      user: state.users.user
    };
  }
)(Nav);