import React, { Dispatch, useEffect, useState } from "react";

import mime from "mime-types"
import { connect, useDispatch } from "react-redux";
import { FileViewItem } from "../../models/fileviewitem";
import { useHistory, useLocation } from "react-router-dom";

import Cookies from 'universal-cookie';
const cookies = new Cookies();


const { REACT_APP_API_URL } = process.env



const DocumentViewer = (props:any) => {

    const history = useHistory();
    let query = useQuery();
    
    const [fileurl, setFileUrl] = useState('')
    const [filetype, setFileType] = useState('')
    const [fileext, setFileExt] = useState('')
    const [hasError, setErrors] = useState(false)

   

   
  
  

    // async function getDocumentURL(_filename: string, _directorypath: string,_fileext:string) {

    //     alert(_filename.trim())
    //     const response = await fetch(`${REACT_APP_API_URL}/directories/fileview`, {
    //         method: "POST",
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             filename: _filename.trim(),
    //             directory_path: _directorypath.trim(),
    //         }),
    //         credentials: 'include',
    //     })

    //     response
    //     .json()
    //     .then(res => {
    //         let filetype: any = mime.lookup(_filename)
    //         var fileviewer = new FileViewItem()
    //         fileviewer.file_url = res.file_url
    //         fileviewer.file_type = filetype
    //         fileviewer.file_ext = _fileext


    //         setFileUrl(res.file_url)
    //         setFileType(filetype)
    //         setFileExt(_fileext)
        
    //     })
    //     .catch(err => setErrors(err));
    // }

    async function getDocumentURL(_id:string) {

        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)

        console.log("getDocumentURL")
        console.log(cookies.get('securego'))

        const response = await fetch(`${REACT_APP_API_URL}/directories/fileview/${_id}`, {
            method: "GET",
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            // body: JSON.stringify({
            //      fileid: _id
          
            // }),
        })

        response
        .json()
        .then(res => {
            console.log(res.file_name)
            let filetype: any = mime.lookup(res.file_name)
            var fileviewer = new FileViewItem()
            fileviewer.file_url = res.file_url
         
            fileviewer.file_ext = res.file_ext
            fileviewer.file_name = res.file_name
            

            setFileUrl(fileviewer.file_url)
            setFileType(filetype)
            setFileExt(fileviewer.file_ext)
        
        })
        .catch(err => setErrors(err));
    }

    useEffect(() => {
       
    //  let fname  = query.get("filename")
    //  let fpath  = query.get("path")
    //  let fext  = query.get("ext")
    //     if(fname && fpath && fext){
    //         getDocumentURL(fname,fpath,fext)
    //     }
      
            
     let fileid = query.get("fileid")
     if(fileid){
        getDocumentURL(fileid)
     }
  
         
    }, [query.get('fileid')])

    // useEffect(() => {
       
    
    //     getDocumentURL(props.match.params.id)
        
      
            
        
  
         
    // }, props.match.params.id)

   

    

    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    const backPage= () => {
        
    }

    
    
 

   
    return (

        <>
            <div>
                
           
                <div className="row h-50">
                    <div className="col-sm-12 h-100 d-table">
                        <div className="card card-block d-table-cell align-middle">

                            {fileurl && fileext === '.mp4' && (
                                <video controls controlsList="nodownload">
                                    <source src={fileurl} type={filetype}></source>
                                </video>
                            )}
                            {fileurl && fileext !== '.mp4' && (
                                <embed src={fileurl} type={filetype}></embed>
                            )} 
                            
                           


                            
                        </div>
                    </div>
                </div>
            </div>

        </>





    )
}
//export default DocumentViewer;
const mapStateToProps = (state: any) => {
    return {
        fileviewitemFromStore: state.documents.fileviewer

    };
}

// const mapDispatchToProps = (dispatch: Dispatch<any>) => {
//     return {


//     }
// }

export default connect(mapStateToProps, null)(DocumentViewer);