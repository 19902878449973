import axios from 'axios';
import React, { Component, Dispatch, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import Menu from './Menu';
import Nav from './Nav';
import {connect} from "react-redux";
import { User } from '../models/user';
import { setUser } from '../redux/actions/setUserAction';
import Footerbg from './Footer';
import { setDirectoryName } from '../redux/actions/documentAction';
import Cookies from 'universal-cookie';


const {REACT_APP_API_URL,REACT_APP_TOKEN} = process.env

const cookies = new Cookies();
console.log(cookies.get('securego'))

const Wrapper = (props: any) => {

    const [redirect,setRedirect] = useState(false);
    
    

    useEffect(() => {
        (
            async () => {
                try {
                    
                    var bearer = 'Bearer ' + cookies.get('securego')
                    console.log("wrapper")
                    console.log(cookies.get('securego'))
                    console.log(bearer)
                    if(cookies.get('securego') === undefined){
                        bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any);
                        console.log("local storage")
                        console.log(bearer)
                    }
                    
                    //const { data } = await axios.get("user");
                    const response = await fetch(`${REACT_APP_API_URL}/user`,{
                        headers:{
                            'Content-Type':'application/json',
                            'Authorization': bearer
                        }
                       
                        //credentials: 'include',
                    })

                   const data = await response.json();

                   var user = new User()
                   user.id = data.id
                   user.first_name = data.first_name
                   user.last_name = data.last_name
                   user.email = data.email
                   user.role = data.role


                    props.setUser(user);
                    
                   
                } catch (e) {
                    setRedirect(true);
                }


            }
        )();
    }, []);

    if(redirect){
        return <Redirect to="/login"/>
    }

    console.log(props.children);
    
    return (
        <>
            <div>
            <Nav/>
            </div>
            <div className="container-fluid py-5 headerbg">
                {/* <img src={headerbg} height="300px" width="100%"/> */}
                <div className="col-md-5 p-lg-5 mx-auto my-5 text-center">
                    <h1 className="display-5 fw-bold" style={{ "color": "white" }}>{props.directoryname}</h1>
                </div>

            </div>

            <div className="container-fluid">
                <div className="row">
                    {/* <Menu/> */}

                    <main className="col-md-12 ms-sm-auto col-lg-12">
                        {props.children}
                    </main>
                </div>
            </div>

            <footer className="footer mt-auto py-3 pt-5">
             <Footerbg/>
              </footer>
        </>
    );

}

const mapStateToProps = (state: any) => {
    return {
        user: state.users.user,
        directoryname: state.directorys.directoryname
    };
}


const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setUser: (user: User) => dispatch(setUser(user)),
        setDirectoryName: (directoryname: string) => dispatch(setDirectoryName(directoryname)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);