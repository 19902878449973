import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';
import { Permission } from '../../../models/permission';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')


const { REACT_APP_API_URL } = process.env

const Permissions = () => {
    const [permissions, setPermissions] = useState([]);
    useEffect(() => {
        (
            async () => {
                //const {data} = await axios.get('roles');

                const response = await fetch(`${REACT_APP_API_URL}/permissions`, {
                    //headers: { 'Content-Type': 'application/json' },
                    //credentials: 'include',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }

                })

                const data = await response.json();


                setPermissions(data);
            }
        )();
    }, []);

    const del = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this record?')) {

            await fetch(`${REACT_APP_API_URL}/permissions/${id}`, {
                method: "DELETE",
               // headers: { 'Content-Type': 'application/json' },
                //credentials: 'include',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization': bearer
                }

            })

            setPermissions(permissions.filter((r: Permission) => r.id !== id));

        }
    }
    return (
        <AdminWrapper>
            <div className="pt-3 pb-2 mb-3 border-bottom">
                <Link to="/admin/permissions/create" className="btn btn-sm btn-outline-secondary">Add</Link>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>

                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {permissions.map((d: Permission) => {
                            return (
                                <tr key={d.id}>

                                    <td>{d.name}</td>
                                    <td>
                                        <div className="btn-group mr-2">
                                            <Link to={`/admin/permissions/${d.id}/edit`} className="btn btn-sm btn-outline-secondary">Edit</Link>
                                            <a href="#" className="btn btn-sm btn-outline-secondary" onClick={() => del(d.id)}>Delete</a>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </AdminWrapper>
    )
}

export default Permissions;