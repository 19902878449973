
export class DocumentItem {
    constructor(
        public id:string = "",
        public name:string = '',
        public doc_type:string = '',
       
    ){
        
    }
}