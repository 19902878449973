import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';
import { Role } from '../../../models/role';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const {REACT_APP_API_URL} = process.env

const AdminDocumentCreate = () => {
    const [name, setName] = useState('');
    
   
    const [redirect, setRedirect] = useState(false);


  

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

       

        // await axios.post('users', {
        //     first_name,
        //     last_name,
        //     email,
        //     role_id: Number(role_id)
        // });

        var bearer = 'Bearer ' + cookies.get('securego')
        await fetch(`${REACT_APP_API_URL}/directories/createdirectory`, {
            method: 'POST',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
           // mode: "no-cors",
            body: JSON.stringify({
                name: name,
            })
        })

        setRedirect(true);
    }

    if (redirect) {
        return <Redirect to="/admin/documents" />
    }

    return (
        <AdminWrapper>
            <form onSubmit={submit}>
                <div className="mb-3">
                    <label>Directory Name</label>
                    <input className="form-control" onChange={e => setName(e.target.value)} />
                </div>
             

                <button className="btn btn-outline-secondary">Save</button>
            </form>
        </AdminWrapper>
    )
};

export default AdminDocumentCreate;