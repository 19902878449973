export class Folder {
    constructor(
        public id:string = '',
        public name:string = '',
        public created:string = '',
        public updated:string = '',
        public documents:string = '',
    ){
        
    }
}