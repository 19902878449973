
import { DocumentItem } from "../../models/documentitem";
import { FileViewItem } from "../../models/fileviewitem";
import { Navigation } from "../../models/navigation";



//1.Create Actions
export const addDocument = (doc: DocumentItem) => {
    return {
        type: 'ADD_DOCUMENT',
        payload: doc
    }
}

export const deleteDocument = (doc: DocumentItem) => {
    return {
        type: 'DELETE_DOCUMENT',
        payload: doc
    }
}

export const setDocumentViewer = (doc: FileViewItem) => {
    return {
        type: 'SET_DOCUMENT_VIEWER',
        payload: doc
    }
}


export const setDirectoryName = (directory: string) => {
    return {
        type: 'SET_DIRECTORY_NAME',
        payload: directory
    }
}






