import axios from 'axios';
import React, { SyntheticEvent, useState } from 'react';
import { Redirect } from 'react-router';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')


const {REACT_APP_API_URL} = process.env

const AdminLogin = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [hasError, setErrors] = useState(false)

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    //  const {data} = await axios.post('login',{
    //       email,
    //       password
    //   });
    var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
    const response = await fetch(`${REACT_APP_API_URL}/admin/login`, {
      method: 'POST',
      headers:{
        'Content-Type':'application/json',
        'Authorization': bearer
    },
      body: JSON.stringify({
        email,
        password
      })
    })

    // const data = await response.json();


    
    // if(data.message === "success"){
    //   setRedirect(true);
    // }else{
    //   alert("User/Password incorrect")
    // }

    //console.log(data)
    response
    .json()
    .then(res => {

      if(res.message === "success"){
        cookies.set("securego", res.token)
        setRedirect(true);
      }else{
        alert(res.messsage)
      }




    })
    .catch(err => setErrors(err));
    
  }

  if (redirect) {
    return <Redirect to={'/admin'} />;
  }

  if(cookies.get('securego')){
    return <Redirect to={'/admin'} />;
  }

  return (
    <main className="form-signin">
      <form onSubmit={submit}>
        <h1 className="h3 mb-3 fw-normal">Administrator</h1>

        <input type="email" className="form-control" placeholder="Email" required
          onChange={e => setEmail(e.target.value)}
        />
        <input type="password" className="form-control" placeholder="Password" required
          onChange={e => setPassword(e.target.value)}
        />


        <button className="w-100 btn btn-lg btn-primary" type="submit">Submit</button>

      </form>
    </main>
  )
}

export default AdminLogin;