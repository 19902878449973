import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reference } from "@popperjs/core";
import dayjs from "dayjs";
import React, { Dispatch, SyntheticEvent, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Document } from "../models/document";
import { DocumentItem } from "../models/documentitem";
import { Navigation } from "../models/navigation";
import { User } from "../models/user";
import DocumentViewer from "../pages/directories/DocumentViewer";
import { addDocument, deleteDocument, setDocumentViewer } from '../redux/actions/documentAction';
import mime from "mime-types"
import { FileViewItem } from "../models/fileviewitem";
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom'
import Modal from 'react-modal';
import { DocumentSpan } from "typescript";
import { DocumentsRecycle } from "../models/documentsrecycle";

import FileSaver, { saveAs } from 'file-saver';
import { GApageView } from "..";

import Cookies from 'universal-cookie';
const cookies = new Cookies();



const { REACT_APP_API_URL, REACT_APP_LOCAL_URL } = process.env

const Documentitem = (props: any) => {
    const dispatch = useDispatch();

    const history = useHistory();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [recycles, SetRecycles] = useState([])

    const [folderID, setFolderID] = useState("")
    const [folderName, setFolderName] = useState("")
    const [documents, SetDocuments] = useState([])
    const [hasError, setErrors] = useState(false)
    const [navigations, SetNavigations] = useState<Navigation[]>([]);


    const [searchResult, SetSearchResult] = useState("")
    const [searchStatus, SetSearchStatus] = useState(false)
    const [keyword, SetKeyword] = useState("")

    const [fileurl, setFileUrl] = useState('')
    const [filetype, setFileType] = useState('')



    const openModal = () => {

        setIsOpen(true);
    }
    const closeModal = () => {

        setIsOpen(false);
    }


    useEffect(() => {

        setFolderID(props.folderid)
        setFolderName(props.foldername)

    }, [props.folderid])

    useEffect(() => {

        if (folderID !== "") {
            fetchData()
            addNavigations(folderID, folderName)



        }


    }, [folderID])



    // search
    useEffect(() => {

        if (props.keyword !== "") {

            SetSearchStatus(true)
            fetchSearch(props.keyword)
        }


    }, [props.keyword])



    const addNavigations = (_id: string, _name: string) => {

        let myArr = [...navigations]

        if (myArr.length === 0) {
            var navigate = new Navigation()
            navigate.id = _id
            navigate.name = _name
            SetNavigations(n => [...navigations, navigate]);
        } else {

            let myIndex = myArr.findIndex((d) => d.id == _id)
            if (myIndex < 0) {
                var navigate = new Navigation()
                navigate.id = _id
                navigate.name = _name
                SetNavigations(n => [...navigations, navigate]);
            } else {
                SetNavigations(myArr.splice(0, myIndex + 1))
            }
        }


        props.addnavigatemain([...navigations])







    }



    async function fetchData() {
        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
        console.log("fetch data all doc")
        const response = await fetch(`${REACT_APP_API_URL}/directories/documentbyfolder/${folderID}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearer
            }
        })
      
        response
            .json()
            .then(res => {
                console.log(res[0].documents)
                SetDocuments(res[0].documents)
            })
            .catch(err => setErrors(err));
    }



    async function fetchSearch(_keyword: string) {
        console.log("search")
        console.log(props.keyword)
        console.log(getDirectoryPath())
        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
        const response = await fetch(`${REACT_APP_API_URL}/directories/searchall`, {
            method: "POST",
            // headers: { 'Content-Type': 'application/json' },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': bearer
            },
           // mode: "no-cors",
            body: JSON.stringify({
                keyword: _keyword.trim(),
                directory_path: getDirectoryPath(),
            }),
            // credentials: 'include',
        })

        response
            .json()
            .then(res => {
                console.log("result searching....")
                console.log(res.Documents)
                console.log(res.SearchResult)


                SetDocuments([])
                if (res.SearchResult > 0) {
                    SetDocuments(res.Documents)
                }





            })
            .catch(err => setErrors(err));
    }



    useEffect(() => {


        fetchData();





    }, [props.refreshFromStore])


    async function getDocumentURL(_filename: string, _directorypath: string, _fileext: string) {

        var convertName = `filename="${_filename}"&path="${_directorypath}"&ext="${_fileext}"`
        const win: any = window.open(`${REACT_APP_LOCAL_URL}/directories/documentviewer?${convertName}`, "_blank");
        win.focus();





    }
    async function getDocumentURLByID(_fileID: string) {


        const win: any = window.open(`${REACT_APP_LOCAL_URL}/directories/documentviewer?fileid=${_fileID}`, "_blank");
        win.focus();





    }


    async function getDocumentURL2(_id: string) {
        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
       
       
        const response = await fetch(`${REACT_APP_API_URL}/directories/fileview/${_id}`, {
            method: "GET",
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
         
        })

        response
            .json()
            .then(res => {
                //  let filetype: any = mime.lookup(_filename)
                var fileviewer = new FileViewItem()
                fileviewer.file_url = res.file_url
                fileviewer.file_type = res.file_type
                fileviewer.file_ext = res.file_ext



                const win: any = window.open(res.file_url, "_blank");
                win.focus();




            })
            .catch(err => setErrors(err));







    }
    function getDirectoryPath() {
        var directoryPath: string = ""
        navigations.map((d: Navigation) => {
            directoryPath = directoryPath + "/" + d.name
        })

        if (directoryPath === "") {
            directoryPath = "/" + folderName
        }
        return directoryPath
    }
    const handleViewFile = async (e: SyntheticEvent, _id: string, _name: string, _fileext: string, _path: string) => {
        e.preventDefault()

      
        GApageView(_name);


        if (_fileext === ".PDF" || _fileext === ".pdf" || _fileext === ".mp4") {
            //getDocumentURL(_name, _path, _fileext)
            getDocumentURLByID(_id)
        } else {
           
            getDocumentURL2(_id)
            alert("Downloaded success")
        }

        
    





    }

    const handleClickFolder = async (e: SyntheticEvent, _id: string, _name: string) => {

        addNavigations(_id, _name)

        props.setfolderbyclick(_id, _name)


    }

    const handleClickNavigate = async (e: SyntheticEvent, _id: string, _name: string) => {
        e.preventDefault();

        if (searchStatus === true) {
            SetSearchStatus(false)
            fetchData()

        } else {
            SetSearchStatus(false)
        }

        addNavigations(_id, _name)
        props.setfolderbyclick(_id, _name)

        GApageView(_name);


    }



    const handleSelected = async (e: any, _id: string, _name: string, _doctype: string) => {

        var docData = new DocumentItem()
        docData.id = _id
        docData.name = _name
        docData.doc_type = _doctype


        if (e.target.checked === true) {
            dispatch(addDocument(docData));
        } else {
            dispatch(deleteDocument(docData));

        }



    }

    async function getRecycles(_foldername: string) {
        var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
        const response = await fetch(`${REACT_APP_API_URL}/directories/recycles/${_foldername}`, {
            method: "GET",
            //headers: { 'Content-Type': 'application/json' },
           // credentials: 'include',
           headers:{
            'Content-Type':'application/json',
            'Authorization': bearer
        },
       // mode: "no-cors"
        })

        response
            .json()
            .then(res => {

                SetRecycles(res)


            })
            .catch(err => setErrors(err));







    }
    const handleClickRecycle = async (e: SyntheticEvent) => {
        e.preventDefault()

        await getRecycles(props.directoryname)

        openModal()

        GApageView("recycle");

    }


    return (
        <>

            <div className="row p-2">
                <div className="col">
                    <span style={{ color: '#898989', fontWeight: 'bold' }}>Documents</span>

                    {navigations.length > 0 && navigations.map((n: Navigation) => {

                        return (

                            <span onClick={(e) => handleClickNavigate(e, n.id, n.name)} style={{ cursor: "pointer" }}  >
                                &nbsp;<span style={{ color: '#898989', fontWeight: 'bold' }}>  &nbsp;&gt;  &nbsp; </span>&nbsp;
                                {navigations[navigations.length - 1].id === n.id ? (
                                    <span style={{ fontWeight: 'bold', color: '#007CFF' }}>{n.name}</span>
                                ) : (
                                    <>
                                        {n.name}
                                    </>
                                )}

                            </span>
                        )
                    })}
                </div>



            </div>
            <div className="row p-2">
                <div className="col">
                    <div className="float-start">
                        <span style={{ color: '#898989', fontWeight: 'bold' }}> Results: &nbsp; {documents.length}</span>
                    </div>
                    <div className="float-end">



                        <span style={{ color: '#898989', fontWeight: 'bold' }}>
                            <button className="btn" onClick={(e) => handleClickRecycle(e)}>Recycle</button>
                        </span>



                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col">
                    <table className="table table-borderless" style={{ border: '0px solid #DDDDDD', fontSize: '18px' }}>
                        <thead>
                            <tr
                                style={{
                                    border: '0px solid #DDDDDD'
                                }}
                            >
                                <th></th>
                                <th><FontAwesomeIcon icon={["far", "file"]} /></th>
                                <th style={{ color: "#898989" }}>Name</th>
                                <th style={{ color: "#898989" }}>Modified</th>
                                <th style={{ color: "#898989" }}>Modified&nbsp;by</th>
                            </tr>
                        </thead>
                        <tbody className="shadow p-3 mb-5 bg-white rounded">

                            {documents.length > 0 && documents.map((d: Document) => {


                                return (
                                    <tr key={d.id}
                                        style={{
                                            border: '1px solid #DDDDDD',



                                            color: '#000000'

                                        }}
                                    >
                                        <td>
                                            {props.user.role.name === "editor" || props.user.role.name === "admin" ? (
                                                <input type="checkbox" defaultChecked={false} onChange={(e) => handleSelected(e, d.id, d.name, d.doc_type)} />
                                            ) : (
                                                <span></span>
                                            )}

                                        </td>
                                        <td>
                                            {d.doc_type === "folder" && (
                                                <FontAwesomeIcon icon={["fas", "folder-open"]} style={{ color: 'orange' }} />
                                            )}
                                            {d.doc_type === ".pdf" && (
                                                <FontAwesomeIcon icon={["fas", "file-pdf"]} style={{ color: 'red' }} />
                                            )}
                                            {d.doc_type === ".xlsx" && (
                                                <FontAwesomeIcon icon={["fas", "file-excel"]} style={{ color: 'green' }} />
                                            )}
                                            {d.doc_type === ".xlsm" && (
                                                <FontAwesomeIcon icon={["fas", "file-excel"]} style={{ color: 'green' }} />
                                            )}
                                            {d.doc_type === ".xls" && (
                                                <FontAwesomeIcon icon={["fas", "file-excel"]} style={{ color: 'green' }} />
                                            )}
                                            {d.doc_type === ".docx" && (
                                                <FontAwesomeIcon icon={["fas", "file-word"]} style={{ color: 'blue' }} />
                                            )}
                                            {d.doc_type === ".doc" && (
                                                <FontAwesomeIcon icon={["fas", "file-word"]} style={{ color: 'blue' }} />
                                            )}

                                            {d.doc_type === ".jpg" && (
                                                <FontAwesomeIcon icon={["fas", "image"]} style={{ color: 'blue' }} />
                                            )}
                                            {d.doc_type === ".jpeg" && (
                                                <FontAwesomeIcon icon={["fas", "image"]} style={{ color: 'blue' }} />
                                            )}
                                            {d.doc_type === ".gif" && (
                                                <FontAwesomeIcon icon={["fas", "image"]} style={{ color: 'blue' }} />
                                            )}
                                            {d.doc_type === ".png" && (
                                                <FontAwesomeIcon icon={["fas", "image"]} style={{ color: 'blue' }} />
                                            )}
                                            {d.doc_type === ".ppt" && (
                                                <FontAwesomeIcon icon={["fas", "file-powerpoint"]} style={{ color: 'blue' }} />
                                            )}
                                            {d.doc_type === ".pptx" && (
                                                <FontAwesomeIcon icon={["fas", "file-powerpoint"]} style={{ color: 'blue' }} />
                                            )}
                                            {d.doc_type === ".mp4" && (
                                                <FontAwesomeIcon icon={["fas", "file-video"]} style={{ color: 'blue' }} />
                                            )}
                                        </td>
                                        <td>
                                            {d.doc_type === "folder" ? (
                                                <span onClick={(e) => handleClickFolder(e, d.id, d.name)} style={{ cursor: "pointer" }}>{d.name}</span>
                                            ) : (
                                                <span onClick={(e) => handleViewFile(e, d.id, d.name, d.doc_type, d.directory_path)} style={{ cursor: "pointer" }}>{d.name}</span>
                                            )}
                                        </td>
                                        <td>

                                            {d.name !== "" ? dayjs(d.updated_at).format('MMMM DD,YYYY') : ""}
                                        </td>
                                        <td>
                                            {d.updated.first_name}
                                        </td>

                                    </tr>
                                )




                            })}
                        </tbody>
                    </table>
                </div>


                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}

                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.3)'
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            background: 'white',
                            border: '0px solid black'
                        },
                    }}
                    contentLabel="Example Modal"

                >

                    <div className="card mb-3 border-0" >
                        <div className="card-header bg-transparent border-0">
                            <div className="container">

                                <div className="row">
                                    <div className="col-11" style={{ fontSize: "20px" }}><b>View Recycle</b></div>
                                    <div className="col-1">
                                        <button className="btn" onClick={closeModal}>X</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body text-success border-0">

                            <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                                <table className="table table-striped table-sm">
                                    <thead>
                                        <tr>

                                            <th>Document Name</th>
                                            <th>Deleted</th>
                                            <th>Deleted&nbsp;by</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recycles.length > 0 && recycles.map((d: DocumentsRecycle) => {
                                            return (
                                                <tr key={d.id}>

                                                    <td>{d.name}</td>

                                                    <td>{d.name !== "" ? dayjs(d.created_at).format('MMMM DD,YYYY') : ""}</td>
                                                    <td>{d.created.first_name} {d.created.last_name}</td>


                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <div className="card-footer bg-transparent border-0">

                            <div className="d-flex justify-content-end">
                                {/* <button className="btn btn-primary" onClick={() => viewSuperUsercloseModal()}>Close</button> */}

                            </div>
                        </div>
                    </div>


                </Modal>


            </div>

        </>



    )
}

const mapStateToProps = (state: any) => {
    return {
        refreshFromStore: state.refreshs,
        fileviewer: state.documents.fileviewer,
        user: state.users.user,
        directoryname: state.directorys.directoryname

    };
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        addDocument: (doc: Document) => dispatch(addDocument(doc)),
        deleteDocument: (doc: Document) => dispatch(deleteDocument(doc)),
        setDocumentViewer: (doc: FileViewItem) => dispatch(setDocumentViewer(doc)),


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documentitem);
