import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { configureStore } from './redux/configureStore';

import {Provider} from "react-redux";
import 'bootstrap/scss/bootstrap.scss';
import ReactGA from 'react-ga';

export const initGA = () => {       
  ReactGA.initialize(process.env.REACT_APP_GA_ID!); // put your tracking id here
} 

export const GApageView = (page:any) => {   
  ReactGA.pageview(page);   
}
//useEffect(() => { GApageView("landing"); }, []);

export const GAmodalView = (modal:any) => {
  ReactGA.modalview(modal);
};
//GAmodalView("Request Demo");
export const GAevent = (categoryName:any, eventName:any) => {
  ReactGA.event({       
      category: categoryName,  // Required
      action: eventName,       // Required
      label: 'labelName',       
      value: 10,       
      nonInteraction: false     
  });   
}
{/* <Button onClick={ () => GAevent('User','Get Started_up') }>   
    GET STARTED 
</Button> */}

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
