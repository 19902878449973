import axios from 'axios';
import React, { Component, Dispatch, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import Menu from './Menu';
import Nav from './Nav';
import {connect} from "react-redux";
import { User } from '../models/user';
import { setUser } from '../redux/actions/setUserAction';
import AdminMenu from './AdminMenu';
import AdminNav from './AdminNav';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const {REACT_APP_API_URL} = process.env

const AdminWrapper = (props: any) => {

    const [redirect,setRedirect] = useState(false);
    const [hasError, setErrors] = useState(false)
    const [adminRedirect,setAdminRedirect] = useState(false);

    useEffect(() => {
        (
            async () => {
                try {
                    var bearer = 'Bearer ' + cookies.get('securego')
                    //const { data } = await axios.get("user");
                    const response = await fetch(`${REACT_APP_API_URL}/user`,{
                        //headers:{'Content-Type':'application/json'},
                        //credentials: 'include',
                        headers:{
                            'Content-Type':'application/json',
                            'Authorization': bearer
                        },
                        
                    })

                    response
            .json()
            .then(res => {
                console.log("AdminWrapper")
                console.log(res)
                if(res.id){
                    var user = new User()
                    user.id = res.id
                    user.first_name = res.first_name
                    user.last_name = res.last_name
                    user.email = res.email
                    user.role = res.role
                    
                    if(user.role.name != "admin"){
                        setAdminRedirect(true)
                    }
    
                     props.setUser(user);
    
                       console.log(res)
    
                }else{
                    setRedirect(true);
                }
               
                  
                 


            })
            .catch(err => setErrors(err));
                
                   
                } catch (e) {
                    setRedirect(true);
                }


            }
        )();
    }, []);

    if(redirect){
        return <Redirect to="/admin/login"/>
    }

    if(adminRedirect){
        return <Redirect to={'/directories/61adf4ee-28fb-48fe-87ef-148f704bf0a1/ISO'} />;
    }

    
    
    return (
        <>
            <div>
            <AdminNav/>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <AdminMenu/>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        {props.children}
                    </main>
                </div>
            </div>
        </>
    );

}

const mapStateToProps = (state: any) => {
    return {
        user: state.users.user
    };
}

// const mapStateToProps = (state:any) => ({
//     user: state.users.user // Notice TodoApp is used instead of todos
// });

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setUser: (user: User) => dispatch(setUser(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminWrapper);