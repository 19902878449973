import axios from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';
import { Permission } from '../../../models/permission';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var bearer = 'Bearer ' + cookies.get('securego')


const {REACT_APP_API_URL} = process.env

const RoleCreate = () => {
    const [permissions,setPermissions] = useState([]);
    const [selected,setSelected] = useState([] as number[])
    const [name,setName] = useState('');
    const [redirect,setRedirect] = useState(false);

    useEffect(() => {
        (
            async () => {
                //const {data} = await axios.get('permissions');

                const response = await fetch(`${REACT_APP_API_URL}/permissions`,{
                    //headers:{'Content-Type':'application/json'},
                    //credentials: 'include',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }
                })
        
               const data = await response.json();

                setPermissions(data);
            }
        )();
    },[])


    const check = (id: number) => {
        if(selected.some(s => s === id)){
            setSelected(selected.filter(s => s !== id));
            return;
        }

        setSelected([...selected,id]);
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

       
        await fetch(`${REACT_APP_API_URL}/permissions`, {
            method: 'POST',
            //headers: { 'Content-Type': 'application/json' },
            //credentials: 'include',
            headers:{
                'Content-Type':'application/json',
                'Authorization': bearer
            },
            body: JSON.stringify({
                name: name,
            })
        })
           
        setRedirect(true);
    }

    if(redirect){
        return <Redirect to="/admin/permissions"/>;
    }

    return (
        <AdminWrapper>
            <form onSubmit={submit}>
                <div>
                   <label className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input className="form-control" onChange={e => setName(e.target.value)}/>
                    </div>
                </div>

             

                <button className="btn btn-outline-secondary">Save</button>
            </form>
        </AdminWrapper>
    )
}

export default RoleCreate;