import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminWrapper from '../../../components/AdminWrapper';
import Paginator from '../../../components/Paginator';
import RichEditor from '../../../components/RichEditor';

import { User } from '../../../models/user';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const { REACT_APP_API_URL } = process.env
const Users = () => {

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    useEffect(() => {
        (
            async () => {
                //const { data } = await axios.get('users?page=${page}');
                var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
                const response = await fetch(`${REACT_APP_API_URL}/users?page=${page}`, {
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': bearer
                    }
                })

                const data = await response.json();

                console.log("data")
                console.log(data)

                setUsers(data.data);
                setLastPage(data.meta.last_page);

                console.log(data.data)
            }
        )()
    }, [page]);


    const next = () => {
        if (page <= lastPage) {
            setPage(page + 1);
        }

    }

    const prev = () => {
        if (page >= 1) {
            setPage(page - 1);
        }
    }

    const del = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this record?')) {
            var bearer = 'Bearer ' + JSON.parse(localStorage.getItem("securego") as any)
            await fetch(`${REACT_APP_API_URL}/users/${id}`, {
                method: "DELETE",
                headers:{
                    'Content-Type':'application/json',
                    'Authorization': bearer
                }
               
            })
            setUsers(users.filter((u: User) => u.id !== id));
        }
    }

    return (
        <AdminWrapper>
            <div className="pt-3 pb-2 mb-3 border-bottom">
                <Link to="/admin/users/create" className="btn btn-sm btn-outline-secondary">Add</Link>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>

                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user: User) => {
                            return (
                                <tr key={user.id}>

                                    <td>{user.first_name} {user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role.name}</td>
                                    <td>
                                        <div className="btn-group mr-2">
                                            <Link to={`/admin/users/${user.id}/edit`} className="btn btn-sm btn-outline-secondary">Edit</Link>
                                            <a href="#" className="btn btn-sm btn-outline-secondary" onClick={() => del(user.id)}>Delete</a>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>

            <Paginator page={page} lastPage={lastPage} pageChanged={setPage} />

          
        </AdminWrapper>
    );

}

export default Users;